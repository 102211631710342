import {Controller} from "stimulus"
import {Howl} from "howler"

export default class extends Controller {
  connect() {
    run_obsolete_code()
  }
}

function run_obsolete_code() {
  $(function() {
    window.QuestionReader = {
      introPauseDuration: 0.9, // seconds
      thinkingPauseDuration: 3,
      nextPauseDuration: 2,
      maxLoopCount: 60, // Attention: Value is hard coded in HTML too
      loopCount: 0,
      voiceGender: 'male',
      volume: 100,
      data: null,

      // Options for state:
      // - 'initializing'
      // - 'initialized'
      // - 'waiting'
      // - 'stopping sounds'
      // - 'playing question intro'
      // - 'playing question'
      // - 'playing answer intro'
      // - 'playing answer'
      state: null,

      sound: null,
      intermediateSounds: {
        'male': {},
        'female': {}
      },

      init: function() {
        if (this.state == 'initializing' || this.state == 'initialized') {
          return;
        }

        var self = this;

        this.state = 'initializing';

        this.updateSettingsUI();
        this.initUIListeners();

        this.loadNext(function() {
          var genders = ['male', 'female'];

          // Question Intro
          for (var i = 0; i < genders.length; i++) {
            self.intermediateSounds[genders[i]]['question_intro'] = new Howl({
              src: self.getSoundUrls(genders[i], 'question_intro'),
              html5: true,
              onplay: function() {
                $('#category_name').fadeOut();
                $('#question_body').fadeOut();
              },
              onloaderror: function(id, message) {
                // TODO: Handle this error gracefully.
                console.log({type: 'onloaderror', id: id, message: message});
              },
              onplayerror: function(id, message) {
                // TODO: Handle this error gracefully.
                console.log({type: 'onplayerror', id: id, message: message});
              },
              onend: function() {
                self.state = 'waiting';
                self.disableAudioControl();

                setTimeout(function() {
                  self.playQuestion();
                }, self.introPauseDuration * 1000);
              }
            });
          }

          // Answer Intro
          for (var i = 0; i < genders.length; i++) {
            self.intermediateSounds[genders[i]]['answer_intro'] = new Howl({
              src: self.getSoundUrls(genders[i], 'answer_intro'),
              html5: true,
              onloaderror: function(id, message) {
                // TODO !!!
                console.log({id: id, message: message});
              },
              onend: function() {
                self.state = 'waiting';
                self.disableAudioControl();

                setTimeout(function() {
                  self.playAnswer();
                }, self.introPauseDuration * 1000);
              }
            });
          }

          self.updateAudioControl('<i class="fa fa-play" aria-hidden="true"></i> ' + "Los geht's!");
        });
      },

      getVoiceGender: function() {
        var voiceGender = Cookies.get('voice_gender');

        if (typeof voiceGender === 'undefined') {
          return this.voiceGender;
        } else {
          return voiceGender;
        }
      },

      setVoiceGender: function(voiceGender) {
        Cookies.set('voice_gender', voiceGender);
        this.voiceGender = voiceGender;
      },

      getVolume: function() {
        var volume = Cookies.get('sound_volume');

        if (typeof volume === 'undefined') {
          return this.volume;
        } else {
          return volume;
        }
      },

      setVolume: function(volume) {
        Cookies.set('sound_volume', volume);
        this.volume = volume;

        Howler.volume(volume / 100);
        $("label[for='volume_range'] > span").html(volume);
      },

      getThinkingPauseDuration: function() {
        var thinkingPauseDuration = Cookies.get('thinking_pause_duration');

        if (typeof thinkingPauseDuration === 'undefined') {
          return this.thinkingPauseDuration;
        } else {
          return thinkingPauseDuration;
        }
      },

      setThinkingPauseDuration: function(pauseDuration) {
        Cookies.set('thinking_pause_duration', pauseDuration);

        this.thinkingPauseDuration = pauseDuration;
        $("label[for='thinking_time_range'] > span").html(pauseDuration);
      },

      getNextPauseDuration: function() {
        var nextPauseDuration = Cookies.get('next_pause_duration');

        if (typeof nextPauseDuration === 'undefined') {
          return this.nextPauseDuration;
        } else {
          return nextPauseDuration;
        }
      },

      setNextPauseDuration: function(pauseDuration) {
        Cookies.set('next_pause_duration', pauseDuration);

        this.nextPauseDuration = pauseDuration;
        $("label[for='answer_question_pause_range'] > span").html(pauseDuration);
      },

      initUIListeners: function() {
        var self = this;

        $('#volume_range').on('change', function() {
          self.setVolume(this.value);
        });

        $('#thinking_time_range').on('change', function() {
          self.setThinkingPauseDuration(this.value);
        });

        $('#answer_question_pause_range').on('change', function() {
          self.setNextPauseDuration(this.value);
        });

        $("input[name='voice_gender']").on('change', function() {
          self.setVoiceGender(this.value);
        });
      },

      loadNext: function(successCallback) {
        this.state = 'loading question';
        this.updateAudioControl('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...', true);

        var self = this;

        $.get('/tests/random_audio_attempts/new')
          .done(function(data) {
            self.state = 'initialized';
            self.data = data;
            successCallback();
          })
          .fail(function() {
            $('#audio_control').replaceWith('<p>Es ist ein Fehler aufgetreten. <a href="/tests/random_audio_attempts">Bitte versuche es erneut.</a></p>');
          });
      },

      saveAttempt: function() {
        $.post('/tests/random_audio_attempts', {audio_attempt: {question_id: this.data.question.id}})
          .fail(function() {
            // Do nothing.
          });
      },

      onButton: function() {
        switch (this.state) {
          case 'waiting':
            // Waiting between different audios
            break;
          case 'initialized':
            this.showFinishButton();
            this.playQuestionIntro();
            break;
          case 'playing question intro':
          case 'playing question':
          case 'playing answer intro':
          case 'playing answer':
            if (this.sound.playing()) {
              this.sound.pause();
              this.updateAudioControlToResume();
            } else {
              this.sound.play();
              this.updateAudioControlToPause();
            }
            break;
        }
      },

      showFinishButton: function() {
        $('#finish_button').show();
      },

      updateAudioControlToPause: function() {
        this.updateAudioControl('<i class="fa fa-pause" aria-hidden="true"></i> Pause');
      },

      updateAudioControlToResume: function() {
        this.updateAudioControl('<i class="fa fa-play" aria-hidden="true"></i> Fortfahren');
      },

      stopSounds: function() {
        this.state = 'stopping sounds';

        if (this.sound != null) {
          this.sound.stop();
        }
      },

      getSoundUrls: function(gender, type) {
        return this.data.question.audio_urls[gender][type].urls;
      },

      getIntermediateSound: function(type) {
        return this.intermediateSounds[this.getVoiceGender()][type];
      },

      updateAudioControl: function(caption = null, disabled = false) {
        var audioControl = $('#audio_control');

        if (caption != null) {
          audioControl.html(caption);
        }

        audioControl.prop('disabled', disabled);
      },

      disableAudioControl: function() {
        this.updateAudioControl(null, true);
      },

      updateSettingsUI: function() {
        $("label[for='volume_range'] > span").html(this.getVolume());
        $("#volume_range").val(this.getVolume());

        $("label[for='thinking_time_range'] > span").html(this.getThinkingPauseDuration());
        $("#thinking_time_range").val(this.getThinkingPauseDuration());

        $("label[for='answer_question_pause_range'] > span").html(this.getNextPauseDuration());
        $("#answer_question_pause_range").val(this.getNextPauseDuration());

        if (this.getVoiceGender() == 'male') {
          $('#male_voice').prop('checked', true);
        } else {
          $('#female_voice').prop('checked', true);
        }
      },

      playQuestionIntro: function() {
        if (this.state == 'stopping sounds') {
          return;
        }

        this.state = 'playing question intro';
        this.updateAudioControlToPause();

        var self = this;

        this.sound = this.getIntermediateSound('question_intro');
        this.sound.play();
      },

      playQuestion: function() {
        if (this.state == 'stopping sounds') {
          return;
        }

        this.state = 'playing question';
        this.updateAudioControlToPause();

        var self = this;

        this.sound = new Howl({
          src: this.getSoundUrls(this.getVoiceGender(), 'question'),
          html5: true,
          onloaderror: function(id, message) {
            // TODO !!!
            console.log({id: id, message: message});
          },
          onplay: function() {
            $('#category_name').hide().html(self.data.question.category).fadeIn();
            $('#question_body').hide().html(self.data.question.body).fadeIn(function() {
              $('html, body').animate({
                scrollTop: $('#bottom').offset().top
              });
            });
          },
          onend: function() {
            this.unload();
            self.state = 'waiting';
            self.disableAudioControl();

            setTimeout(function() {
              self.playAnswerIntro();
            }, self.getThinkingPauseDuration() * 1000);
          }
        });

        this.sound.play();
      },

      playAnswerIntro: function() {
        if (this.state == 'stopping sounds') {
          return;
        }

        this.state = 'playing answer intro';
        this.updateAudioControlToPause();

        var self = this;

        this.sound = this.getIntermediateSound('answer_intro');
        this.sound.play();
      },

      playAnswer: function() {
        if (this.state == 'stopping sounds') {
          return;
        }

        this.state = 'playing answer';
        this.updateAudioControlToPause();

        var self = this;

        this.sound = new Howl({
          src: this.getSoundUrls(this.getVoiceGender(), 'correct_answer'),
          html5: true,
          onloaderror: function(id, message) {
            console.log({id: id, message: message});
          },
          onend: function() {
            this.unload();
            self.state = 'waiting';
            self.disableAudioControl();

            self.loopCount += 1;

            if (self.loopCount >= self.maxLoopCount) {
              window.location.href = '/tests/random_audio_attempts';
              return;
            } else {
              setTimeout(function() {
                self.saveAttempt();
                self.loadNext(function() {
                  self.playQuestionIntro();
                });
              }, self.getNextPauseDuration() * 1000);
            }
          }
        });

        this.sound.play();
      }
    };

    QuestionReader.init();

    $('#audio_control').on('click', function() {
      QuestionReader.onButton(this);
    });
  });

  document.addEventListener("turbolinks:before-cache", function() {
    QuestionReader.stopSounds();
  })
}
