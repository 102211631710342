import {Controller} from "stimulus"
import {ekkoLightbox} from "ekko-lightbox"

export default class extends Controller {
  connect() {
    run_obsolete_code()
  }
}

function run_obsolete_code() {
  $(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
  });
}
