import Shepherd from 'shepherd.js'

class TutorialService {
  constructor() {
    this._defaultTourOptions = {
      useModalOverlay: true,
      defaultStepOptions: {
        cancelIcon: {
          enabled: true
        }
      }
    }
  }

  currentTour() {
    return Shepherd.activeTour;
  }

  start_tour(name) {
    let self = this;

    setTimeout(function() {
      self[name]();
    }, 300);
  }

  finish_tour(name, action) {
    $.ajax({
      url: '/tutorial_states/' + name + '?last_action=' + action,
      type: 'put'
    });
  }

  // ------------------------------------------------------------
  // Test 01
  // ------------------------------------------------------------
  test_01() {
    let tour = new Shepherd.Tour(this._defaultTourOptions);

    tour.on('cancel', function() {
      self.finish_tour('test_01', 'canceled');
    });

    tour.addStep({
      id: 0,
      text: 'Hallo und willkommen zur ersten Frage! Wir erläutern Dir in aller Kürze diese zentrale Funktion von ' + window.app_title + '.',
      buttons: [
        {
          text: 'Weiter',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 1,
      title: 'Kategorie der Frage',
      text: 'Zu welcher Kategorie die folgende Frage gehört siehst Du hier. Je nach Lernmodus variiert die Kategorie. Solltest Du nur eine Kategorie im Modus "Zufällige Fragen aus bestimmten Kategorien" ausgewählt haben, dann bleibt die Kategorie gleich.',
      attachTo: {
        element: '#tutorial_step_1',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Weiter',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 2,
      title: 'Die Frage',
      text: 'Diese Frage gilt es zu beantworten. Es gibt Multiple-Choice- und offene Fragen. Bei offenen Fragen werden keine Antwortmöglichkeiten vorgegeben.',
      attachTo: {
        element: '#tutorial_step_2',
        on: 'top'
      },
      buttons: [
        {
          text: 'Weiter',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 3,
      title: 'Deine Antwortmöglichkeiten',
      text: 'Zu der gestellten Frage gibt es diese Antwortmöglichkeiten aus denen Du die Deiner Meinung nach richtige Antwort auswählen musst.',
      attachTo: {
        element: '#tutorial_step_3',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Weiter',
          action: tour.hide
        }
      ]
    });

    let self = this;
    tour.addStep({
      id: 4,
      title: 'Sicher?',
      text: 'Entscheide, wie sicher Du Dir mit Deiner Antwort bist. Später kannst Du Fragen wiederholen, bei denen Du Dir nicht sicher warst.',
      attachTo: {
        element: '#tutorial_step_certainty',
        on: 'bottom'
      },
      buttons: [
        {
          text: 'Fertig',
          action: tour.next
        }
      ]
    }).on('show', function() {
      self.finish_tour('test_01', 'completed');
    });

    tour.start();
  }

  // ------------------------------------------------------------
  // Attempt Result 01
  // ------------------------------------------------------------
  attempt_result_01() {
    let tour = new Shepherd.Tour(this._defaultTourOptions);

    tour.on('cancel', function() {
      self.finish_tour('attempt_result_01', 'canceled');
    });

    tour.addStep({
      id: 1,
      title: 'Deine Antwort',
      text: 'Ob Deine Antwort richtig oder falsch war, wird hier angezeigt. Fragen, die Du falsch beantwortet hast, kannst Du später wiederholen.',
      attachTo: {
        element: '#tutorial_step_1',
        on: 'top'
      },
      buttons: [
        {
          text: 'Weiter',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 2,
      title: 'Notizen',
      text: 'Zu jeder Frage kannst Du Dir Notizen machen. Optional kannst Du auch Bilder an eine Notiz anhängen, zum Beispiel Fotos von Skizzen oder von handgeschriebenen Notizen.',
      attachTo: {
        element: '#tutorial_step_2',
        on: 'top'
      },
      buttons: [
        {
          text: 'Weiter',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 3,
      title: 'Schwierige Frage?',
      text: 'Manche Fragen wirst Du schwieriger finden als andere, unabhängig davon ob Deine Antwort richtig oder falsch war. Schwierige Fragen kannst Du markieren und später im entsprechenden Lernmodus gesondert wiederholen und vertiefen.',
      attachTo: {
        element: '#tutorial_step_3',
        on: 'top'
      },
      buttons: [
        {
          text: 'Weiter',
          action: tour.next
        }
      ]
    });

    let self = this;
    tour.addStep({
      id: 4,
      title: 'Nächste Frage',
      text: 'Weiter geht es zur nächsten Frage!',
      attachTo: {
        element: '#tutorial_step_4',
        on: 'top'
      },
      buttons: [
        {
          text: 'Fertig',
          action: tour.next
        }
      ]
    }).on('show', function() {
      self.finish_tour('attempt_result_01', 'completed');
    });

    tour.start();
  }
}

export let tutorialService = new TutorialService()
