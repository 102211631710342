import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    function show_only_open_questions_switch() {
      $('#only_open_questions_switch').parent().show();
    }

    function hide_only_open_questions_switch() {
      $('#only_open_questions_switch').parent().hide();
    }

    if ($('#include_open_questions_switch').prop('checked') == false) {
      hide_only_open_questions_switch();
    }

    $('#include_open_questions_switch').change(function(e) {
      var options = {
        url: 'settings/open_questions',
        type: '',
        contentType: "application/json; charset=utf-8",
        dataType: 'json'
      };

      if (e.target.checked) {
        options['type'] = 'PUT';
        options['url'] += '/1';

        show_only_open_questions_switch();
      } else {
        options['type'] = 'DELETE';

        hide_only_open_questions_switch();
      }

      $.ajax(options);
    });

    $('#only_open_questions_switch').change(function(e) {
      var options = {
        url: 'settings/only_open_questions',
        type: '',
        contentType: "application/json; charset=utf-8",
        dataType: 'json'
      };

      if (e.target.checked) {
        options['type'] = 'PUT';
        options['url'] += '/1';
      } else {
        options['type'] = 'DELETE';
      }

      $.ajax(options);
    });

    $('#difficult_question_switch').change(function(e) {
      var id = $('#attempt_id').val();

      var options = {
        url: '/attempts/' + id + '/difficulty',
        type: 'PUT',
        contentType: "application/json; charset=utf-8",
        dataType: 'json'
      };

      $.ajax(options);
    });

    $('#exclude_question_switch').change(function(e) {
      var id = $('#attempt_id').val();

      var options = {
        url: '/attempts/' + id + '/exclude_question',
        type: 'PUT',
        contentType: "application/json; charset=utf-8",
        dataType: 'json'
      };

      $.ajax(options);
    });
  }
}
