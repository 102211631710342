window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Unterstützt werden nur Dateien im Format JPEG, PNG oder PDF.")
  }

  const maxFileSize = 1024 * 1024 * 15
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert('Die maximale Größe eines Bildes darf 15 MB nicht überschreiten.')
  }
})
