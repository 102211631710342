export class PaymentService {
  constructor(paymentMethod, packageId, providerPublishableKey, paymentIntentClientSecret, userEmail) {
    // @param {string} paymentMethod[sepa_debit|card]
    this._paymentMethod = paymentMethod;

    this._packageId = packageId;

    this._providerPublishableKey = providerPublishableKey;

    this._paymentIntentClientSecret = paymentIntentClientSecret;

    this._userEmail = userEmail;

    this._locale = 'de';
    this._errorMessagesElementId = 'payment_error_messages';
    this._paymentFormElementId = 'payment_form';
    this._paymentMethodElement = null;
    this._stripe = null;
  }

  get paymentMethod() {
    return this._paymentMethod;
  }

  get packageId() {
    return this._packageId;
  }

  get providerPublishableKey() {
    return this._providerPublishableKey;
  }

  get paymentIntentClientSecret() {
    return this._paymentIntentClientSecret;
  }

  get locale() {
    return this._locale;
  }

  get errorMessagesElementId() {
    return this._errorMessagesElementId;
  }

  get paymentFormElementId() {
    return this._paymentFormElementId;
  }

  get userEmail() {
    return this._userEmail;
  }

  get paymentMethodElement() {
    return this._paymentMethodElement;
  }

  set paymentMethodElement(value) {
    this._paymentMethodElement = value;
  }

  get stripe() {
    return this._stripe;
  }

  set stripe(value) {
    this._stripe = value;
  }

  setup() {
    this.prepareStripeElement()

    if (!this.stripe) {
      return;
    }

    const form = document.getElementById(this.paymentFormElementId);

    switch (this.paymentMethod) {
      case 'sepa_debit':
        this.setupSepaDebitListener(form)
        break;
      case 'card':
        this.setupCardListener(form);
        break;
    }
  }

  setupSepaDebitListener(form) {
    let self = this;

    form.addEventListener('submit', function(event) {
      event.preventDefault();

      self.stripe.confirmSepaDebitPayment(
        self.paymentIntentClientSecret, {
          payment_method: {
            sepa_debit: self.paymentMethodElement,
            billing_details: {
              name: form.querySelector('input[name="name"]').value,
              email: self.userEmail
            }
          }
        }
      ).then(self.paymentIntentCallback.bind(null, self.packageId));
    });
  }

  setupCardListener(form) {
    let self = this;

    form.addEventListener('submit', function(event) {
      event.preventDefault();

      self.stripe.confirmCardPayment(
        self.paymentIntentClientSecret, {
        payment_method: {
          card: self.paymentMethodElement,
          billing_details: {
            email: self.userEmail
          }
        }
      }).then(self.paymentIntentCallback.bind(null, self.packageId));
    });
  }

  paymentIntentCallback(packageId, result) {
    if (typeof result.paymentIntent !== 'undefined') {
      fetch('/charges', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_intent_id: result.paymentIntent.id,
          package: packageId
        }),
      })
      .then(function() {
        // TODO Handle server error.
        window.location.href = '/tests';
      })
    } else {
      document.getElementById('payment_container').style.display = 'none';

      let errorContainer = document.getElementById('payment_error_container')
      errorContainer.style.display = 'block';

      let errorElement = errorContainer.querySelector('.error_message');
      errorElement.innerHTML = result.error.message;
    }
  }

  prepareStripeElement() {
    if (!document.getElementById(this.paymentMethod)) {
      return;
    }

    this.stripe = Stripe(this.providerPublishableKey);
    const elements = this.stripe.elements({locale: this.locale});
    const style = {};

    const options = {
      style: style
    }

    switch (this.paymentMethod) {
      case 'sepa_debit':
        options['supportedCountries'] = ['SEPA'];
        options['placeholderCountry'] = this.locale.toUpperCase();
        this.paymentMethodElement = elements.create('iban', options);
        break;
      case 'card':
        options['hidePostalCode'] = true;
        this.paymentMethodElement = elements.create('card', options);
        break;
    }

    const errorMessagesElement = document.getElementById(this.errorMessagesElementId);

    this.paymentMethodElement.mount(`#${this.paymentMethod}`);

    this.paymentMethodElement.on('change', function(event) {
      if (event.error) {
        errorMessagesElement.textContent = event.error.message;
      } else {
        errorMessagesElement.textContent = '';
      }
    });
  }
}
