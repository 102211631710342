// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

// TODO: This Cookies thingy needs to be fixed.
import Cookies from "js-cookie"
window.Cookies = Cookies

$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

require("trix")
require("@rails/actiontext")

import "./trix-overrides"

import {PaymentService} from "../services/payment_service"
window.PaymentService = PaymentService

import Confetti from "canvas-confetti";
window.Confetti = Confetti;

import { CountUp } from 'countup.js';
window.CountUp = CountUp;

$(document).on('turbolinks:load', function() {
  window.captchaSuccess = function() {
    var registrationButton = document.querySelector('input[type=submit]#register');
    registrationButton.disabled = false;
  }

  var selector = '.edit_question input[type=checkbox][name^="question[answers_attributes]"]';
  selector +=  ', .new_question input[type=checkbox][name^="question[answers_attributes]"]';

  var selectorChecked = '.edit_question input[type=checkbox][name^="question[answers_attributes]"]:checked';
  selectorChecked += ', .new_question input[type=checkbox][name^="question[answers_attributes]"]:checked';

  $(selector).change(function() {
    if ($(selectorChecked).length > 0) {
      $('.alert-correct-answer').hide();
    } else {
      $('.alert-correct-answer').show();
    }
  });

  $('.character-goal-popup, .goal-popup-overlay').on('click', function() {
    $('.character-goal-popup, .goal-popup-overlay').hide();
  });
})
