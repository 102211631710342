import {Controller} from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['messageBody', 'messagesList', 'latestGroupMessageId']

  connect() {
    let groupMessagesController = this

    this.subscription = consumer.subscriptions.create(
      {
        channel: 'GroupMessagesChannel',
        key: 'group_messages/' + this.data.get('groupId') + '/message'
      },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },
        disconnected() {
          // Called when the subscription has been terminated by the server
        },
        received(data) {
          let parentMessageId = groupMessagesController.data.get('parentMessageId')

          if (parentMessageId != '' && parentMessageId != data.parentMessageId) {
            // Discard this message, because we are in thread / sub message
            // and this message is not meant to be shown here right now

            return
          }

          groupMessagesController.prependResponse(data.body, data.groupMessageId)
        }
      }
    );
  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  sendMessage() {
    let messageBody = this.messageBodyTarget.inputElement.value
    let csrfToken = document.head.querySelector("[name='csrf-token']").content
    let groupId = this.data.get('groupId')
    let parentMessageId = this.data.get('parentMessageId')

    fetch('/groups/' + groupId + '/group_messages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({group_message: {body: messageBody, group_message_id: parentMessageId}}),
    })
    .then(response => response.text())
    .then(html => {
      this.prependResponse(html)
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  loadMessages() {
    let csrfToken = document.head.querySelector("[name='csrf-token']").content
    let groupId = this.data.get('groupId')
    let latestGroupMessageId = this.data.get('latestGroupMessageId')
    let parentMessageId = this.data.get('parentMessageId')

    fetch('/groups/' + groupId + '/group_messages?latest_group_message_id=' + latestGroupMessageId + '&parent_group_message_id=' + parentMessageId, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
    .then(response => response.text())
    .then(html => {
      if (html.trim().length < 1) {
        return
      }

      this.messagesListTarget.insertAdjacentHTML('beforeend', html)
      this.updateLatestGroupMessageId()
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  prependResponse(html, messageId) {
    if (messageId && document.querySelector("[data-group-messages-latest-group-message-id='" + messageId + "']")) {
      return
    }

    this.messagesListTarget.insertAdjacentHTML('afterbegin', html)

    if (this.data.get('latestGroupMessageId') == '') {
      this.updateLatestGroupMessageId()
    }

    this.messageBodyTarget.editor.loadHTML();
  }

  updateLatestGroupMessageId() {
    let groupMessages = this.element.querySelectorAll('div.group_message')

    if (groupMessages.length > 0) {
      let latestGroupMessage = groupMessages[groupMessages.length - 1]
      this.data.set('latestGroupMessageId', latestGroupMessage.dataset['groupMessagesLatestGroupMessageId'])
    }
  }
}
