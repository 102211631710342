import {Controller} from "stimulus"
import {tutorialService} from '../services/tutorial_service'

export default class extends Controller {
  connect() {
    if (document.documentElement.hasAttribute('data-turbolinks-preview')) {
      return
    }

    let tutorialName = this.data.get('current')

    if (tutorialName !== null) {
      tutorialService.start_tour(this.data.get('current'))
    }
  }

  finish() {
    let tutorialName = this.data.get('name')
    tutorialService.finish_tour(tutorialName, 'completed')
  }
}
