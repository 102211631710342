import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    run_obsolete_code()
  }
}


function run_obsolete_code() {
  $('.post-vote').off('click').on('click', function (event) {
    event.preventDefault();
    var post_id = $(this).data('post-id');
    var vote_type = $(this).data('vote-type');

    $.post('/posts/' + post_id + '/' + vote_type + '/', function(data) {
      $('[data-vote-count="' + post_id + '"]').replaceWith(data);
    });
  })
}
