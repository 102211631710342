import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['amount', 'amountDisplay'];

  connect() {
    $('body').on('click', '.daily-goal[data-toggle="modal"]', function() {
      $($(this).data("target")+' .modal-content').load('/daily_goal');
    });

    this.amount = parseInt(this.amountTarget.value);
  }

  add(event) {
    let amount = parseInt(event.target.dataset['amount']);

    this.amount += amount;
    this.amountDisplayTarget.textContent = this.amount;
    this.amountTarget.value = this.amount;
  }

  reset() {
    this.amount = 0;
    this.amountDisplayTarget.textContent = this.amount;
    this.amountTarget.value = this.amount;
  }
}
