import {Controller} from "stimulus"
import {tutorialService} from '../services/tutorial_service'

export default class extends Controller {
  connect() {
    run_obsolete_code()
  }
}

// ------------------------------------------------------------
// Obsolete code that needs to be rewritten the Stimulus way
// ------------------------------------------------------------


function run_obsolete_code() {
  function updateCategoriesCount() {
    // Hack to prevent effects on other pages
    if (document.getElementById('selected-categories') == null) {
      return
    }

    let selected_categories = $('#selected-categories li')
    let selected_categories_count = selected_categories.length

    if (selected_categories_count <= 0) {
      $('input[type=submit]').attr('disabled', 'disabled');
    } else {
      $('input[type=submit]').removeAttr('disabled', 'disabled');
    }

    $('#selected-categories-count').html(selected_categories_count);
  }

  function deselectAllCategories() {
    let selected_categories = $('#selected-categories li')

    for (var category of selected_categories) {
      category = $(category)
      category.prependTo('#available-categories');
      category.find('.fa-plus-circle').removeClass('d-none');
      category.find('.fa-minus-circle').addClass('d-none');
      category.attr('data-added', 'false');
    }

    updateCategoriesCount()
  }

  updateCategoriesCount()

  $('#clear_selected_categories').on('click', function() {
    deselectAllCategories()
  })

  $('#select_random_categories').on('click', function() {
    deselectAllCategories()

    let selected_categories = $('#available-categories li')

    for (var category of selected_categories) {
      if (Math.random() >= 0.7) {
        category = $(category)
        category.appendTo('#selected-categories');
        category.find('.fa-plus-circle').addClass('d-none');
        category.find('.fa-minus-circle').removeClass('d-none');
        category.attr('data-added', 'true');
      }
    }

    updateCategoriesCount()
  })

  $('[data-category-id]').on('click', function(e) {
    let target = $(e.currentTarget)

    if (target.attr('data-added') == 'true') {
      target.fadeOut(250, function() {
        target.prependTo('#available-categories');
        target.find('.fa-plus-circle').removeClass('d-none');
        target.find('.fa-minus-circle').addClass('d-none');
        target.attr('data-added', 'false');
        target.fadeIn(250, updateCategoriesCount);
      });
    } else {
      target.fadeOut(250, function() {
        target.appendTo('#selected-categories');
        target.find('.fa-plus-circle').addClass('d-none');
        target.find('.fa-minus-circle').removeClass('d-none');
        target.attr('data-added', 'true');
        target.fadeIn(250, updateCategoriesCount);
      });
    }
  });

  $('#new_attempt .test-button').on('click', function(e) {
    deselectAll('#new_attempt .test-button');

    let target = $(e.currentTarget)

    $('#standard_attempt_answer_id').val(target.data('answer-id'));

    target.toggleClass('active');
    displayCertaintyButtons();
    displaySubmitButton();
    scrollToSubmitButton();

    if ((tutorialService.currentTour() !== null) && (tutorialService.currentTour() !== undefined)) {
      tutorialService.currentTour().show(4);
    }
  });

  let openAnswerElement = $('#open_attempt_open_answer')
  if (openAnswerElement) {
    openAnswerElement.val('')
  }

  $('#open_attempt_open_answer').on('input', function(e) {
    if (e.target.value.length > 0) {
      displayCertaintyButtons();
      displaySubmitButton();
      scrollToSubmitButton();
    } else {
      hideSubmitButton();
    }
  });

  $('#new_attempt .certainty-button').on('click', function(e) {
    deselectAll('#new_attempt .certainty-button');

    let target = $(e.currentTarget);

    // TODO: Remove this hack which sets certainty for both question types (standard & open) without knowing which actually needs to be set
    $('#standard_attempt_certainty').val(target.data('certainty'));
    $('#open_attempt_certainty').val(target.data('certainty'));

    target.toggleClass('active');
  });

  function deselectAll(selector) {
    $(selector).removeClass('active');
  }

  function displaySubmitButton() {
    $('#new_attempt [name=submit_attempt]').show();
    $('#new_open_attempt [name=submit_attempt]').show();
  }

  function scrollToSubmitButton() {
    var element = $('input[name=submit_attempt]');
    var scrollToArea = $('#certainty');

    if (!element.isInViewport()) {
      $('html, body').animate({
        scrollTop: scrollToArea.offset().top
      });
    }
  }

  function hideSubmitButton() {
    $('#new_attempt [name=submit_attempt]').hide();
    $('#new_open_attempt [name=submit_attempt]').hide();
  }

  function displayCertaintyButtons() {
    document.getElementById('certainty').classList.remove('invisible')
  }

  // Countdown
  function getTimeRemaining(endtime) {
    var t = endtime - Date.parse(new Date());
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
      'total': t,
      'days': days,
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    };
  }

  function initializeClock(id, endtime) {
    var clock = document.getElementById(id);
    var clockRunning = true;

    if (typeof clock == 'undefined') {
      return;
    }

    // var daysSpan = clock.querySelector('.days');
    var hoursSpan = clock.querySelector('.hours');
    var minutesSpan = clock.querySelector('.minutes');
    var secondsSpan = clock.querySelector('.seconds');

    function updateClock() {
      if (!clockRunning) {
        return;
      }

      var t = getTimeRemaining(endtime);

      // daysSpan.innerHTML = t.days;
      hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
      minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
      secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

      if (t.total <= 0 && clockRunning) {
        clockRunning = false;
        clearInterval(timeinterval);
        var currentTestId = document.getElementById('current_test_id').value;
        Turbolinks.visit('/tests/' + currentTestId);
      }
    }

    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
  }

  var startedAt = document.getElementById('current_test_started_at');
  var duration = document.getElementById('current_test_duration');

  if (startedAt != null || duration != null) {
    // Time test started + 2 hours duration
    var deadline = Date.parse(new Date(parseFloat(startedAt.value))) + parseFloat(duration.value) * 1000;
    initializeClock('clockdiv', deadline);
  }
}
